<template>
  <div class="document">
    <div class="page-header">
      <h1>{{ $t('Delivery Order Management') }} > {{ $t('Delivery Invoice') }} > {{ $t('Edit') }}</h1>
    </div>
    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('Edit Invoice') }}</h2>
      </div>
      <InvoiceForm @action="updateInvoice" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';
import Delivery from '@/lib/delivery';
import InvoiceForm from '@/components/delivery/InvoiceForm.vue';

export default {
  name: 'Invoice',
  components: {
    InvoiceForm
  },
  mounted() {
    this.loadInvoice();
  },
  methods: {
    async updateInvoice(form) {
      try {
        const loginInfo = Common.getLoginInfo();
        const invoiceId = this.$route.params.id;
        const invoice = await Delivery.updateDeliveryOrder(this.apiUrl, invoiceId, form, loginInfo);
        this.$router.push('/delivery-invoices');
      } catch (err) {
        console.log(err);
      }
    },
    async loadInvoice() {
      try {
        const loginInfo = Common.getLoginInfo();
        const invoiceId = this.$route.params.id;
        const invoice = await Delivery.loadDeliveryOrder(this.apiUrl, invoiceId, loginInfo);
        this.setCurrentInvoice(invoice);
      } catch (err) {
        console.log(err);
      }
    },
    ...mapActions(['setCurrentInvoice']),
  },
  computed: mapState({
    currentInvoice: state => state.delivery.currentInvoice,
    langcode: state => state.langcode,
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style scoped lang="scss">
.content-header {
  display: flex;

  h2 {
    flex: 1;
  }

  .action-button {
    margin-right: 20px;
  }
}
</style>